import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/app/customize',
        name: 'customize',
        component: () => import(/* webpackChunkName: "about" */ '../views/CustomizeView.vue')
    },
    {
        path: '/app/custom',
        name: 'custom',
        component: () => import(/* webpackChunkName: "about" */ '../views/CustomView.vue')
    },
    {
        path: '/app/aboutUs',
            name: 'aboutUs',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
    },
    {
        path: '/app/private',
        name: 'private',
        component: () => import(/* webpackChunkName: "about" */ '../views/PrivateView.vue')
    },
    {
        path: '/app/agreement',
        name: 'agreement',
        component: () => import(/* webpackChunkName: "about" */ '../views/AgreementView.vue')
    },
    {
        path: '/app/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "about" */ '../views/FeedbackView.vue')
    },
    {
        path: '/app/question',
        name: 'question',
        component: () => import(/* webpackChunkName: "about" */ '../views/QuestionView.vue')
    },
    {
        path: '/app/recommend',
        name: 'recommend',
        component: () => import(/* webpackChunkName: "about" */ '../views/RecommendView.vue')
    },
    {
        path: '/app/techAgreement',
        name: 'techAgreement',
        component: () => import(/* webpackChunkName: "about" */ '../views/TechAgreement.vue')
    },
    {
        path: '/app/agentAgreement',
        name: 'agentAgreement',
        component: () => import(/* webpackChunkName: "about" */ '../views/AgentAgreement.vue')
    },
    {
        path: '/app/powerAttorney',
        name: 'powerAttorney',
        component: () => import(/* webpackChunkName: "about" */ '../views/PowerAttorney.vue')
    },
    {
        path: '/app/userAgreement',
        name: 'userAgreement',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserAgreementView.vue')
    },
    {
        path: '/app/rent',
        name: 'rent',
        component: () => import(/* webpackChunkName: "about" */ '../views/RentView.vue'),
        meta: {
            title: '星朗租机'
        }
    },
    {
        path: '/app/rentPrivacy',
        name: 'rentPrivacy',
        component: () => import(/* webpackChunkName: "about" */ '../views/RentPrivacy.vue'),
        meta: {
            title: '隐私政策'
        }
    },
    {
        path: '/app/rentAgreement',
        name: 'rentAgreement',
        component: () => import(/* webpackChunkName: "about" */ '../views/RentAgreement.vue'),
        meta: {
            title: '用户服务协议'
        }
    },
    {
        path: '/app/plan',
        name: 'plan',
        component: () => import(/* webpackChunkName: "about" */ '../views/PlanView.vue'),
        meta: {
            title: '推广计划'
        }
    },
    {
        path: '/app/iosV1',
        name: 'iosV1',
        component: () => import(/* webpackChunkName: "about" */ '../views/IosView1.vue'),
        meta: {
            title: '示例报告'
        }
    },
    {
        path: '/app/iosV2',
        name: 'iosV2',
        component: () => import(/* webpackChunkName: "about" */ '../views/IosView2.vue'),
        meta: {
            title: '示例报告'
        }
    },
    {
        path: '/app/iosV3',
        name: 'iosV3',
        component: () => import(/* webpackChunkName: "about" */ '../views/IosView3.vue'),
        meta: {
            title: '示例报告'
        }
    }
]

const router = createRouter({
    base: '/app',
    history: createWebHistory(),
    routes
})
export default router
